class="pointer"
<template>
  <b-card>
    <b-row>
      <b-breadcrumb>
        <b-breadcrumb-item :to="{ name: 'app-scoutsuite' }">
          Scans
        </b-breadcrumb-item>
        <b-breadcrumb-item
          :to="{
            name: 'app-scoutsuite-list',
            params: { id: securityanalysisData.securityanalysisProjectId },
          }"
        >
          {{
            securityanalysisData.securityanalysisProjectName
              .charAt(0)
              .toUpperCase() +
            securityanalysisData.securityanalysisProjectName.slice(1)
          }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ serviceName }} Detail Report
        </b-breadcrumb-item>
      </b-breadcrumb>
    </b-row>
    <b-row class="mb-1">
      <b-col class="d-flex justify-content-start mt-1">
        <h1>{{ serviceName }} Detail Report</h1>
      </b-col>

      <b-col class="d-flex justify-content-end">
        <b-row>
          <b-col class="col-auto">
            <b-button
              variant="primary"
              class="pointer"
              @click="filterByLevel('showAll')"
              >Show All</b-button
            >
          </b-col>

          <b-col class="col-auto">
            <b-button
              variant="success"
              class="pointer"
              @click="filterByLevel('good')"
              >Good</b-button
            >
          </b-col>
          <b-col class="col-auto">
            <b-button
              variant="warning"
              class="pointer"
              @click="filterByLevel('warning')"
              >Warning</b-button
            >
          </b-col>
          <b-col class="col-auto">
            <b-button
              variant="danger"
              class="pointer"
              @click="filterByLevel('danger')"
              >Danger</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div v-for="(findData, findindex) in findingData">
      <span v-for="(value, index) in findData">
        <b-card class="finding-data">
          <b-row>
            <b-col cols="12" lg="10">
              <feather-icon
                v-if="
                  value.level == 'warning' &&
                  value.checked_items !== 0 &&
                  value.flagged_items !== 0
                "
                icon="InfoIcon"
                size="16"
                class="feather-warning feather"
              />
              <feather-icon
                v-if="
                  value.level == 'danger' &&
                  value.checked_items !== 0 &&
                  value.flagged_items !== 0
                "
                icon="InfoIcon"
                size="16"
                class="feather-danger feather"
              />
              <feather-icon
                v-if="value.checked_items == 0 && value.flagged_items == 0"
                icon="CircleIcon"
                size="16"
                class="feather-normal feather"
              />
              <feather-icon
                v-if="value.checked_items !== 0 && value.flagged_items == 0"
                icon="CheckCircleIcon"
                size="16"
                class="feather-success feather"
              />
              <span
                v-if="value.checked_items == 0 || value.flagged_items == 0"
                >{{ index }}</span
              >
              <span v-else>{{ index }}</span>
            </b-col>
            <b-col cols="12" lg="2" class="d-flex justify-content-end">
              <b-button
                variant="primary"
                class=""
                @click="viewRoles(index, value.dashboard_name)"
                size="sm"
              >
                <span>View Details</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </span>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BTable,
  BPagination,
  BFormCheckbox,
  BFormInput,
  VBToggle,
  BCollapse,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import router from "@/router";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BTable,
    BPagination,
    BFormCheckbox,
    BFormInput,
    VBToggle,
    BCollapse,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      servicesKey: router.currentRoute.params.id,
      scoutsuiteResult: null,
      searchQuery: "",
      findingData: [],
      expand: 0,
      securityanalysisData: JSON.parse(
        localStorage.getItem("securityanalysisData")
      ),
      serviceName: "",
    };
  },
  beforeMount() {
    this.getFindings();
  },
  watch: {
    "$store.state.app.userData.default_region_id": {
      handler() {
        router.push({ name: "app-scoutsuite" });
      },
    },
  },
  methods: {
    viewRoles(index, item) {
      let databaseName = item.split(" ").join("_").toLowerCase();
      let databaseNameIndex = databaseName.concat(":", index);
      this.$router.push(
        "/apps/security-scan/" +
          this.securityanalysisData.securityanalysisProjectId +
          "/" +
          this.servicesKey +
          "/" +
          databaseNameIndex
      );
      localStorage.setItem(
        "securityanalysisDatabaseNameIndex",
        JSON.stringify(this.servicesKey)
      );
    },
    filterByLevel(findingLevel) {
      this.findingData = [];
      const data = [];

      for (const [servicesKey, servicesValue] of Object.entries(
        this.scoutsuiteResult.services
      )) {
        if (router.currentRoute.params.id == servicesKey) {
          if (servicesValue.findings) {
            if (findingLevel !== "showAll") {
              for (const [findingKey, findingValue] of Object.entries(
                servicesValue.findings
              )) {
                if (
                  findingValue.level == findingLevel &&
                  findingValue.checked_items !== 0 &&
                  findingValue.flagged_items !== 0
                ) {
                  data.push({
                    [findingKey]: findingValue,
                  });
                } else if (findingLevel == "good") {
                  if (
                    findingValue.level !== findingLevel &&
                    findingValue.checked_items !== 0 &&
                    findingValue.flagged_items == 0
                  ) {
                    data.push({
                      [findingKey]: findingValue,
                    });
                  }
                }
              }
              this.findingData = data;
            } else {
              const dangerData = [];
              const warningData = [];
              const goodData = [];

              for (const [findingKey, findingValue] of Object.entries(
                servicesValue.findings
              )) {
                if (
                  findingValue.level == "danger" &&
                  findingValue.checked_items !== 0 &&
                  findingValue.flagged_items !== 0
                ) {
                  dangerData.push({
                    [findingKey]: findingValue,
                  });
                } else if (
                  findingValue.level == "warning" &&
                  findingValue.checked_items !== 0 &&
                  findingValue.flagged_items !== 0
                ) {
                  warningData.push({
                    [findingKey]: findingValue,
                  });
                } else if (
                  findingValue.checked_items !== 0 &&
                  findingValue.flagged_items == 0
                ) {
                  goodData.push({
                    [findingKey]: findingValue,
                  });
                }
              }
              const dataforall = [];
              dataforall.push(dangerData);
              dataforall.push(warningData);
              dataforall.push(goodData);

              for (const [Key, Value1] of Object.entries(dataforall)) {
                for (const [Key, Value] of Object.entries(Value1)) {
                  data.push(Value);
                }
              }
              this.findingData = data;
            }
          }
        }
      }
    },
    resultQuery() {
      if (this.searchQuery) {
        this.searchQueryData = this.findingData.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.includes(v));
        });
        return this.searchQueryData;
      } else {
        return this.findingData;
      }
    },
    getFindings() {
      this.scoutsuiteResult = JSON.parse(
        sessionStorage.getItem("scoutsuiteResult")
      );
      for (const [key, value] of Object.entries(
        this.scoutsuiteResult.services
      )) {
        if (router.currentRoute.params.id == key) {
          if (value.findings) {
            const data = [];
            const dangerData = [];
            const warningData = [];
            const goodData = [];

            for (const [findingKey, findingValue] of Object.entries(
              value.findings
            )) {
              if (
                findingValue.level == "danger" &&
                findingValue.checked_items !== 0 &&
                findingValue.flagged_items !== 0
              ) {
                dangerData.push({
                  [findingKey]: findingValue,
                });
              } else if (
                findingValue.level == "warning" &&
                findingValue.checked_items !== 0 &&
                findingValue.flagged_items !== 0
              ) {
                warningData.push({
                  [findingKey]: findingValue,
                });
              } else if (
                findingValue.checked_items !== 0 &&
                findingValue.flagged_items == 0
              ) {
                goodData.push({
                  [findingKey]: findingValue,
                });
              }
            }
            const dataforall = [];
            dataforall.push(dangerData);
            dataforall.push(warningData);
            dataforall.push(goodData);

            for (const [Key, Value1] of Object.entries(dataforall)) {
              for (const [Key, Value] of Object.entries(Value1)) {
                data.push(Value);
              }
            }
            this.findingData = data;
          }
          const objFinding = Object.keys(value?.findings)[0];
          this.serviceName = objFinding
            ? value.findings[objFinding].service
            : key;
        }
      }
    },
    data(id) {
      if (id == this.expand) {
        this.expand = 0;
      } else {
        this.expand = id;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.pointer {
  cursor: pointer;
}
.finding-data {
  margin: 0;
  border: 1px solid $border-color;
  border-radius: 0;
}
.feather {
  margin-right: 8px;
}
</style>
